var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-list-gallery"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.boards, function (board) {
    return _c('div', {
      key: board._id,
      staticClass: "col-6 col-sm-4 col-md-12"
    }, [_c('div', {
      staticClass: "board-list-gallery__inner"
    }, [_c('a', {
      staticClass: "board-list-gallery__link",
      attrs: {
        "href": ""
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('click', board);
        }
      }
    }, [_c('div', {
      staticClass: "board-list-gallery__left"
    }, [_c('div', {
      staticClass: "board-list-gallery__img-wrap"
    }, [_c('div', {
      staticClass: "board-list-gallery__img",
      style: {
        backgroundImage: `url('${board.thumb}')`
      }
    })])]), _c('div', {
      staticClass: "board-list-gallery__right"
    }, [_c('div', {
      staticClass: "board-list-gallery__con"
    }, [_c('div', {
      staticClass: "board-list-gallery__tit"
    }, [_c('span', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(board.subject))]), _c('div', {
      staticClass: "board-list-gallery__more"
    }, [_c('v-btn', {
      staticClass: "font-size-14 font-size-lg-16 font-weight-medium pa-4 ml-auto mr-n4",
      attrs: {
        "small": "",
        "text": "",
        "color": "primary"
      }
    }, [_vm._v("VIEW MORE "), _c('i', {
      staticClass: "icon icon-bar mt-2 ml-6"
    })])], 1)]), _c('div', {
      staticClass: "board-list-gallery__date"
    }, [_vm._v(_vm._s(board.createdAt.toDate()))]), _c('div', {
      staticClass: "board-list-gallery__txt"
    }, [_vm._v(" " + _vm._s(board.summary) + " ")])])])])])]);
  }), 0)]), !_vm.hidePagination ? _vm._t("pagination") : _vm._e(), _c('div', {
    staticClass: "board-buttons board-buttons--center"
  }, [!_vm.hideWriteButton ? _vm._t("write-button") : _vm._e()], 2)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }